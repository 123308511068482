import * as React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import DarkUnica from "highcharts/themes/dark-unica";
import FinanceApi from "../../api/FinanceApi";
import { PriceModel } from "../../api/models/PriceModel";
DarkUnica(Highcharts);
let indicators = require("highcharts/indicators/indicators");
indicators(Highcharts);
let vbp = require("highcharts/indicators/volume-by-price");
vbp(Highcharts);

export interface StockChartCardProps {
  ticker?: string;
}

export interface StockChartCardState {
  options: any;
  priceData: Array<PriceModel>;
  ticker?: string;
}

export default class StockChartTile extends React.Component<
  StockChartCardProps,
  StockChartCardState
> {
  api: FinanceApi = new FinanceApi();
  chartComponent: React.RefObject<any>;
  constructor(props: StockChartCardProps) {
    super(props);
    this.chartComponent = React.createRef();
    this.state = {
      priceData: [],
      options: {},
      ticker: this.props.ticker,
    };
  }

  componentDidMount() {
    if (
      (this.props.ticker && !this.state.ticker) ||
      this.state.ticker !== this.props.ticker
    ) {
      console.log(
        "render for ticker: " + this.state.ticker + " vs " + this.props.ticker
      );
      this.setState({
        ticker: this.props.ticker,
      });

      this.api
        .getPrice(this.props.ticker!, undefined, undefined)
        .then((result: any) => {
          this.setState({
            priceData: result,
          });

          this.prepareData(result);
        });
    }
  }
  componentDidUpdate() {
    if (
      (this.props.ticker && !this.state.ticker) ||
      this.state.ticker !== this.props.ticker
    ) {
      console.log(
        "updated ticker: " + this.state.ticker + " vs " + this.props.ticker
      );
      this.setState({
        ticker: this.props.ticker,
        options: undefined,
      });

      this.api
        .getPrice(this.props.ticker!, undefined, undefined)
        .then((result: any) => {
          this.setState({
            priceData: result,
          });

          this.prepareData(result);
        });
    }
    window.resizeTo(window.innerWidth, window.innerHeight);

    if (this.chartComponent && this.chartComponent.current) {
      const container = this.chartComponent.current.container.current;

      container.style.height = "100%";
      container.style.width = "100%";
      this.chartComponent.current.chart.reflow();
    }
  }

  prepareData = (data: Array<PriceModel>) => {
    const groupingUnits = [
      [
        "week", // unit name
        [1], // allowed multiples
      ],
      ["month", [1, 2, 3, 4, 6]],
    ];

    const chartData = new Array();
    const volume = new Array();

    const dividends = new Array();
    const splits = new Array();

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (item.priceDt) {
        item.priceDt = new Date(item.priceDt);
        chartData.push([
          item.priceDt.getTime(),
          item.open,
          item.high,
          item.low,
          item.close,
        ]);

        if (item.dividendAmount && item.dividendAmount > 0) {
          dividends.push({
            x: item.priceDt.getTime(),
            title: "D",
            text: "Dividend Payout: " + item.dividendAmount,
          });
        }

        if (item.splitCoefficient && item.splitCoefficient > 1) {
          splits.push({
            x: item.priceDt.getTime(),
            title: "S",
            text: "Split Ratio: " + item.splitCoefficient,
          });
        }

        volume.push([item.priceDt.getTime(), item.volume]);
      }
    }

    console.log(chartData);
    console.log(volume);

    const options = {
      rangeSelector: {
        inputEnabled: false,
      },
      subtitle: {
        text: "With SMA and Volume by Price technical indicators",
      },
      chart: {
        backgroundColor: "#1c3144",
      },
      yAxis: [
        {
          startOnTick: false,
          endOnTick: false,
          labels: {
            align: "right",
            x: -3,
          },
          title: {
            text: "OHLC",
          },
          height: "60%",
          lineWidth: 2,
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: "right",
            x: -3,
          },
          title: {
            text: "Volume",
          },
          top: "65%",
          height: "35%",
          offset: 0,
          lineWidth: 2,
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            units: groupingUnits,
          },
        },
      },
      tooltip: {
        split: true,
      },

      series: [
        {
          type: "candlestick",
          name: data[0].ticker,
          data: chartData,
          dataGrouping: {
            units: groupingUnits,
          },
          id: "stock",
          zIndex: 2,
        },
        {
          type: "column",
          name: "Volume",
          id: "volume",
          data: volume,
          yAxis: 1,
        },
        {
          type: "flags",
          data: dividends,
          onSeries: "stock",
          shape: "circlepin",
          width: 16,
        },
        {
          type: "flags",
          data: splits,
          onSeries: "stock",
          shape: "circlepin",
          width: 16,
        },
        {
          type: "sma",
          linkedTo: "stock",
          zIndex: 1,
          marker: {
            enabled: false,
          },
          color: "#D64545",
        },
      ],
    };

    this.setState({
      options: options,
      priceData: chartData,
    });

    window.resizeTo(window.innerWidth, window.innerHeight);

    if (this.chartComponent && this.chartComponent.current) {
      const container = this.chartComponent.current.container.current;

      container.style.height = "100%";
      container.style.width = "100%";
      this.chartComponent.current.chart.reflow();
    }
  };
  public render() {
    if (
      this.state.ticker &&
      this.state.options &&
      this.state.priceData &&
      this.state.priceData.length > 0
    ) {
      return (
        <HighchartsReact
          highcharts={Highcharts}
          ref={this.chartComponent}
          constructorType={"stockChart"}
          options={this.state.options}
        />
      );
    } else if (!this.state.ticker) {
      return (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div>
            <span className="text-light">
              Select a stock to view it's price chart
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div>
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }
  }
}
