import * as React from "react";
import logo from "./logo.png"; // with import
import TreeMenu from 'react-simple-tree-menu';
import 'react-simple-tree-menu/dist/main.css';
import { ListGroupItem, Input, ListGroup } from 'reactstrap';

export interface ISidebarContentProps {
  onSelection: any;
}

export interface ISidebarContentState {}

export default class SidebarContent extends React.Component<
  ISidebarContentProps,
  ISidebarContentState
> {


  showHeatMap = (label: string) => {
    if (label === 'Sector Performance') {
      this.props.onSelection();
    }
  }
  treeData = {
    'first-level-node-1': {               // key
      label: 'Product',
      index: 0, // decide the rendering order on the same level
      nodes: {
        'second-level-node-1': {
          label: 'Stock List',
          index: 0,
        },'second-level-node-2': {
          label: 'Stock Chart',
          index: 0,
        },
        'second-level-node-3': {
          label: 'Sector Performance',
          index: 0,
        }
      },
    },
    'first-level-node-2': {
      label: 'Account',
      index: 1,
      nodes: {
        'second-level-node-1': {
          label: 'Cash Position',
          index: 0,
        }
      }
    },
    'first-level-node-3': {
      label: 'Operations',
      index: 1,
      nodes: {
        'second-level-node-1': {
          label: 'Trade Alerts',
          index: 0,
        }
      }
    },
    'first-level-node-4': {
      label: 'Workflow',
      index: 1,
      nodes: {
        'second-level-node-1': {
          label: 'My Tasks',
          index: 0,
        }
      }
    },
    'first-level-node-5': {
      label: 'Materials',
      index: 1,
      nodes: {
        'second-level-node-1': {
          label: 'Fact Sheet',
          index: 0,
        },'second-level-node-20': {
          label: 'Prospectus',
          index: 0,
        }
      }
    },
    'first-level-node-6': {
      label: 'Client',
      index: 1,
      nodes: {
        'second-level-node-1': {
          label: 'Portfolio Exposure',
          index: 0,
        }
      }
    },
  };

  constructor(props: ISidebarContentProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div className="sideBarWidth">
        <div className="d-flex justify-content-center">
          <div className="">
            <img className="logo" src={logo} />
          </div>
        </div>
        {/* <div className="d-flex justify-content-center">
          <div className="">
            <button className="btn btn-outline-secondary">
              <i className="fa fa-plus mr-2 sidebar"></i>
              <span className="text-light">Add Tile</span>
            </button>
          </div>
        </div> */}
        <TreeMenu data={this.treeData}
          onClickItem={({ key, label, ...props }) => {
            this.showHeatMap(label);
          }}        
        
        />
      </div>
    );
  }
}
