import React from "react";
import "./App.scss";
import { Route, HashRouter } from "react-router-dom";
import Mosaic from "./vyew/Mosaic";
import TestPage from "./TestPage";
import AdminComponent from "./admin/Admin";
import HeatMapTile from "./tiles/stock/HeatMapTile";
export interface IAppState {}
export interface IAppProps {}

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
  }

  render() {
    return (
      <div className="fixed-layout">
        <HashRouter>
          <Route exact={true} path="/" component={Mosaic} />
          <Route exact={true} path="/test" component={TestPage} />
          <Route exact={true} path="/admin" component={AdminComponent}/>
          <Route exact={true} path="/sectorPerf" component={HeatMapTile}/>
        </HashRouter>
      </div>
    );
  }
}
