import * as React from "react";
import { Card, CardBody } from "reactstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import { StockSearchResult } from "../../api/models/StockSearchResult";
import { StockListItem } from "./StockListItem";
const savedStocks: Array<StockSearchResult> = getFromLS("savedStocks") || [];

export interface IStockListCardProps {
  onSelection: any;
}

export interface IStockListCardState {
  allowNew: boolean;
  isLoading: boolean;
  multiple: boolean;
  options: Array<StockSearchResult>;
  selectedTickers: Array<StockSearchResult>;
}

export default class StockListCard extends React.Component<
  IStockListCardProps,
  IStockListCardState
> {
  ref = React.useRef;

  constructor(props: IStockListCardProps) {
    super(props);

    this.state = {
      allowNew: false,
      isLoading: false,
      multiple: false,
      options: [],
      selectedTickers: [],
    };
  }

  componentDidMount() {
    if (savedStocks) {
      this.tickerSelected(savedStocks);
    }
  }

  tickerSelected = (event: Array<StockSearchResult>) => {
    console.log(event);
    let stocks = this.state.selectedTickers;

    if (event) {
      event.forEach((ticker) => {
        stocks.push(ticker);
      });

      if (stocks && stocks.length > 0) {
        this.props.onSelection(stocks[stocks.length - 1].ticker);

        saveToLS("savedStocks", stocks);
      }
    }

    // stocks = stocks.reverse();

    console.log(stocks);
    this.setState({
      selectedTickers: stocks,
    });
  };

  _handleSearch = (query: string) => {
    this.setState({ isLoading: true });
    const url =
      "http://terminal-services-dev.eba-mnkpwq7e.us-east-1.elasticbeanstalk.com/api/finance/stocks?query=" +
      query;
    fetch(url)
      .then((response) => response.json() as Promise<Array<StockSearchResult>>)
      .then((options) => {
        console.log(options);

        this.setState({
          isLoading: false,
          options: options,
        });
      });
  };

  remove = (i: number, e: any) => {
    console.log("remove: " + i);
    console.log(this.state.selectedTickers);
    const stocks = this.state.selectedTickers;
    stocks.splice(i, 1);

    this.setState({
      selectedTickers: stocks,
    });
    saveToLS("savedStocks", stocks);
  };

  graph = (ticker: string) => {
    this.props.onSelection(ticker);
  };

  renderList = () => {
    console.log(this.state.selectedTickers);
    return this.state.selectedTickers.map((stock, i) => {
      return (
        <div key={i}>
          <StockListItem
            ticker={stock.ticker}
            i={i}
            remove={this.remove}
            graph={this.graph}
          />
          <hr />
        </div>
      );
    });
  };

  public render() {
    return (
      <div>
        <div className="mb-3">
          <AsyncTypeahead
            {...this.state}
            id="async-example"
            labelKey={(option: StockSearchResult) =>
              `${option.name} - ${option.ticker}`
            }
            minLength={3}
            onSearch={this._handleSearch}
            onChange={this.tickerSelected}
            placeholder="Search for a stock..."
          />
        </div>
        {this.renderList()}
      </div>
    );
  }
}
function getFromLS(key: string) {
  let ls = [];
  if (global.localStorage) {
    try {
      if (global.localStorage.getItem(key)) {
        ls = JSON.parse(global.localStorage.getItem(key)!);
      } else {
        ls = [];
      }
    } catch (e) {
      /*Ignore*/
    }
  }

  return ls["value"];
}

function saveToLS(key: string, value: Array<StockSearchResult>) {
  if (global.localStorage) {
    global.localStorage.setItem(
      key,
      JSON.stringify({
        value,
      })
    );
  }
}
const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
  obj[key];
