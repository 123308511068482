import React from "react";
import _ from "lodash";
import { WidthProvider, Responsive, Layouts } from "react-grid-layout";
import WorkspaceTile from "../tiles/workspace/WorkspaceTile";
import Sidebar from "react-sidebar";
import Moment from "react-moment";
import SidebarContent from "../SidebarContent";
import StockChartTile from "../tiles/stock/StockChartTile";
import StockListCard from "../tiles/stock/StockList";
import HeatMapTile from "../tiles/stock/HeatMapTile";
import { Link } from "react-router-dom";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

export interface IMosaicProps {}

export interface IMosaicState {
  layouts: Layouts;
  sidebarOpen: boolean;
  items: number;
  selectedTicker?: string;
  showHeatMap: boolean;
}

export default class Mosaic extends React.Component<
  IMosaicProps,
  IMosaicState
> {
  constructor(props: IMosaicProps) {
    super(props);
    console.log("constructor");
    this.state = {
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      sidebarOpen: false,
      items: 4,
      showHeatMap: false
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.generateDOM = this.generateDOM.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMount");
  }

  onLayoutChange(layout: Array<any>, layouts: Layouts) {
    saveToLS("layouts", layouts);
    this.setState({ layouts });
    window.dispatchEvent(new Event("resize"));
  }

  onSetSidebarOpen(open: boolean) {
    this.setState({ sidebarOpen: open });
  }
  routeToAdmin = () => {

  }

  render() {
    return (
      <div>
        <div className="row">
          <Sidebar
            sidebar={<SidebarContent
              onSelection={this.addHeatMapSelected} />}
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={{ sidebar: { background: "#1c3144", width: "800" } }}
          >
            <div className="d-flex flex-row">
              <div className="ml-auto">
                <span className="text-muted">
                  <Moment format="ddd MMM DD, YYYY HH:MM">{new Date()}</Moment>
                </span>
              </div>
              <div className="ml-2 mr-2">
              
                <Link to="/admin"> <i
                  className="fa fa-cog menu"
                 
                ></i></Link>
              </div>
              <div className="ml-2 mr-2">
                <i
                  className="fa fa-bars menu"
                  onClick={() => this.onSetSidebarOpen(true)}
                ></i>
              </div>
            </div>
          </Sidebar>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <ResponsiveReactGridLayout
              className="layout"
              draggableHandle=".handle"
              cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
              rowHeight={30}
              layouts={this.state.layouts}
              onLayoutChange={(layout, layouts) =>
                this.onLayoutChange(layout, layouts)
              }
            >
              {this.generateDOM()}
            </ResponsiveReactGridLayout>
          </div>
        </div>
      </div>
    );
  }

  tickerSelected = (ticker: string) => {
    console.log("ticker event: " + JSON.stringify(ticker));
    this.setState({
      selectedTicker: ticker,
    });
  };

  addHeatMapSelected = () => {
    this.setState({
      showHeatMap: !this.state.showHeatMap
    });
  }

  generateDOM() {
    const that = this;
    // Generate items with properties from the layout, rather than pass the layout directly
    const layout = this.generateLayout();
    return _.map(_.range(this.state.items), function (i) {
      if (i === 0) {
        return (
          <div className="card" key={i} data-grid={layout[i]}>
            <div className="card-header handle">
              <span className="text-light">
                <h5>Workspace</h5>
              </span>
            </div>
            <div className="card-body">
              <WorkspaceTile />
            </div>
          </div>
        );
      } else if (i === 1) {
        return (
          <div className="card" key={i} data-grid={layout[i]}>
            <div className="card-header handle">
              <span className="text-light">
                <h5>
                  Stock Chart
                  {that.state.selectedTicker
                    ? " - " + that.state.selectedTicker
                    : ""}
                </h5>
              </span>
            </div>
            <div className="card-body">
              <StockChartTile ticker={that.state.selectedTicker} />
            </div>
          </div>
        );
      } else if (i === 2) {
        return (
          <div className="card" key={i} data-grid={layout[i]}>
            <div className="card-header handle">
              <span className="text-light">
                <h5>Stock List</h5>
              </span>
            </div>
            <div className="card-body">
              <StockListCard onSelection={that.tickerSelected} />
            </div>
          </div>
        );
      } else if (i === 3 && that.state.showHeatMap) {
        return (
          <div className="card handle" key={i} data-grid={layout[i]}>
            <div className="card-body">
              <HeatMapTile />
            </div>
          </div>
        );
      } else if (i !== 3) {
        return (
          <div className="card handle" key={i} data-grid={layout[i]}>
            <div className="card-body">
              <span className="text">{i}</span>
            </div>
          </div>
        );
      } else {
        return (<div></div>);
      }
    });
  }

  generateLayout(): Array<any> {
    const p = this.props;
    return _.map(new Array(this.state.items + 1), function (item, i) {
      const y: number = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 4,
        h: y * 2,
        i: i.toString(),
      };
    });
  }
}
function getFromLS(key: string) {
  let ls = [];
  if (global.localStorage) {
    try {
      if (global.localStorage.getItem("rgl-8")) {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")!);
      } else {
        ls = [];
      }
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key: string, value: Layouts) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
  obj[key];
