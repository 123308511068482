import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

export interface ITileTableProps {
    rowData: Array<any>;
}

export interface ITileTableState {
    columnDefs: Array<any>;
    rowData: Array<any>;
}

export default class TileTable extends React.Component<ITileTableProps, ITileTableState> {
  
    gridApi: any;
    gridColumnApi: any;
  constructor(props: ITileTableProps) {
    super(props);

    this.state = {
        columnDefs: [{
          headerName: "Name", field: "name"
        }, {
          headerName: "Description", field: "desc"
        }, {
          headerName: "Category", field: "category"
        }],
        rowData: this.props.rowData
      }
  }

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };

  onGridReady = (params: any) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.sizeToFit();
  };

  cellClicked = (params: any) => {
      console.log(params);
      // row index
      // data
  }

  componentDidUpdate() {
    this.gridApi.refreshCells({force: true, suppressFlash: false});
  }

  public render() {
    return (
        <div
        className="ag-theme-alpine-dark"
        style={{
        height: '80vh',
        width: '100%' }}
      >
        <AgGridReact
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          onGridReady={this.onGridReady}
            onCellClicked={this.cellClicked}
            enableCellChangeFlash={true}
            animateRows={true}>
        </AgGridReact>
      </div>
    );
  }
}
