import * as React from "react";
import "../workspace/workspace.scss";
import Select from "react-select";

export interface WorkSpace {
  name: string;
  workspaceEntries: Array<WorkspaceEntry>;
}
export interface WorkspaceEntry {
  id?: string;
  name: string;
  url: string;
  ref?: Window;
  token?: number;
}
export interface IWorkspaceTileProps {}

export interface IWorkspaceTileState {
  workspaces: Array<WorkSpace>;
  activeWorkspace: number;
}

export default class WorkspaceTile extends React.Component<
  IWorkspaceTileProps,
  IWorkspaceTileState
> {
  currentWorkspace: WorkSpace;
  constructor(props: IWorkspaceTileProps) {
    super(props);

    this.state = {
      workspaces: [
        {
          name: "Default",
          workspaceEntries: [
            { name: "Google News", url: "http://news.google.com/" },
            {
              name: "Hacker News",
              url: "http://news.ycombinator.com/",
            },
            {
              name: "Test Page",
              url: "http://vyew-ui.s3-website-us-east-1.amazonaws.com/#/test",
            },
          ],
        },
      ],
      activeWorkspace: 0,
    };
    this.currentWorkspace = this.state.workspaces[0];

    window.addEventListener("message", this.receiveMessage, false);
  }

  componentDidMount() {
    setInterval(() => {
      const entries = this.state.workspaces[0].workspaceEntries;

      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.ref && !entry.token) {
          entry.token = Math.floor(Math.random() * 100000);

          console.log("token: " + entry.token);
          entry.ref.postMessage(entry.token, window.location.href);
        } else if (entry.token) {
          console.log("entry " + entry.name + " is stale, removing.");
          entry.token = undefined;
          entry.ref = undefined;
        }
      }

      const workspaces = this.state.workspaces;
      workspaces[this.state.activeWorkspace].workspaceEntries = entries;
      this.setState({
        workspaces: workspaces,
      });
    }, 3000);
  }

  componentDidUpdate() {}
  receiveMessage = (event: any) => {
    console.log("parent received: " + event.data);

    const entries = this.state.workspaces[this.state.activeWorkspace]
      .workspaceEntries;
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.token === event.data) {
        entry.token = undefined;

        const workspaces = this.state.workspaces;
        workspaces[this.state.activeWorkspace].workspaceEntries = entries;
        this.setState({ workspaces: workspaces });
        return;
      }
    }
  };

  reopenWorkspace = () => {
    const entries = this.state.workspaces[this.state.activeWorkspace]
      .workspaceEntries;

    for (let i = 0; i < entries.length; i++) {
      entries[i].ref = window.open(entries[i].url, "_blank")!;
    }

    const workspaces = this.state.workspaces;
    workspaces[this.state.activeWorkspace].workspaceEntries = entries;
    this.setState({ workspaces: workspaces });
  };

  launchWindow = (i: number) => {
    console.log(i);
    const entries = this.state.workspaces[this.state.activeWorkspace]
      .workspaceEntries;

    entries[i].ref = window.open(entries[i].url, "_blank")!;

    const workspaces = this.state.workspaces;
    workspaces[this.state.activeWorkspace].workspaceEntries = entries;
    this.setState({ workspaces: workspaces });
  };

  getStatus = (ref: any) => {
    if (ref) {
      return <i className="fa fa-check-circle text-success"></i>;
    } else {
      return <i className="fa fa-times-circle text-danger"></i>;
    }
  };
  public render() {
    return (
      <table className="table">
        <tbody>
          {this.state.workspaces[
            this.state.activeWorkspace
          ].workspaceEntries.map((entry, i) => {
            return (
              <tr key={i}>
                <td>
                  <a onClick={() => this.launchWindow(i)}>{entry.name}</a>
                </td>
                <td>{this.getStatus(entry.ref)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
