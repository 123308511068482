import * as React from "react";

export interface ITestPageProps {}

export interface ITestPageState {}

export default class TestPage extends React.Component<
  ITestPageProps,
  ITestPageState
> {
  source: any;
  constructor(props: ITestPageProps) {
    super(props);

    this.state = {};

    window.addEventListener("message", this.receiveMessage, false);
  }

  receiveMessage = (event: any) => {
    console.log(event);
    this.source = event;

    this.source.source.postMessage(event.data, this.source.origin);
  };

  componentDidMount() {}

  public render() {
    return (
      <div>
        <h1>Test</h1>
      </div>
    );
  }
}
