import * as React from "react";
import FinanceApi from "../../api/FinanceApi";

export interface IStockListItemProps {
  ticker: string;
  i: number;
  remove: any;
  graph: any;
}

export interface IStockListItemState {
  quote: any;
  name: string;
  changePc: number;
  changeNum: number;
  price: number;
  high: number;
  low: number;
  open: number;
  i: number;
}

export class StockListItem extends React.Component<
  IStockListItemProps,
  IStockListItemState
> {
  financeApi: FinanceApi = new FinanceApi();
  constructor(props: IStockListItemProps) {
    super(props);
    this.state = {
      i: this.props.i,
      quote: null,
      name: "",
      changePc: 0,
      changeNum: 0,
      price: 0,
      high: 0,
      low: 0,
      open: 0,
    };

    this.getQuote();
  }

  getQuote = () => {
    const api = new FinanceApi();
    const url =
      api.alphaVantageBase +
      "?function=GLOBAL_QUOTE&symbol=" +
      this.props.ticker +
      "&apikey=" +
      api.apiKey;

    console.log("(ticker, url): (" + this.props.ticker + ", " + url + ")");

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        const quote = response;
        let changePc = 0;
        let changeNum = 0;
        let price = 0;
        let high = 0;
        let low = 0;
        let open = 0;
        Object.keys(quote["Global Quote"]).forEach((key) => {
          console.log("key: " + key);
          if (key.includes("change percent")) {
            let temp: string = quote["Global Quote"][key];
            temp = temp.substring(0, temp.indexOf("%"));
            temp = (+temp).toFixed(2);
            changePc = +temp;
          } else if (key.includes("change")) {
            changeNum = +quote["Global Quote"][key];
            changeNum = +changeNum.toFixed(2);
          } else if (key.includes("price")) {
            price = +quote["Global Quote"][key];
            price = +price.toFixed(2);
          } else if (key.includes("high")) {
            high = +quote["Global Quote"][key];
            high = +high.toFixed(2);
          } else if (key.includes("low")) {
            low = +quote["Global Quote"][key];
            low = +low.toFixed(2);
          } else if (key.includes("open")) {
            open = +quote["Global Quote"][key];
            open = +open.toFixed(2);
          }
        });
        this.setState({
          quote: quote,
          changePc: changePc,
          changeNum: changeNum,
          price: price,
          high: high,
          low: low,
          open: open,
        });
      });
  };

  onRemove = (e: any) => {
    this.props.remove(this.state.i, e);
  };

  onGraph = (e: any) => {
    this.props.graph(this.props.ticker);
  };

  public render() {
    return (
      <div>
        <div className="d-flex flex-row">
          <div>
            <span className="text-light">{this.props.ticker}</span>
          </div>
          <div className="ml-2">
            <i
              className="fa fa-line-chart text-primary"
              onClick={(e) => this.onGraph(e)}
            ></i>
          </div>
          <div className="ml-2">
            <i
              className="fa fa-close text-danger"
              onClick={(e) => this.onRemove(e)}
            ></i>
          </div>
          <div className="ml-auto">
            <span className="text-light">{this.state.price}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span className="text-muted cut-off">O: {this.state.open}</span>
            <span className="text-muted cut-off ml-2">
              H: {this.state.high}
            </span>
            <span className="text-muted cut-off ml-2">L: {this.state.low}</span>
          </div>
          {/* <div className="spark-chart">
            <SparkLine data={[5, 4, 3, 2.5, 2]} />
          </div> */}
          <div className="ml-auto">
            <span
              className={
                "badge " +
                (+this.state.changeNum < 0 ? "badge-danger" : "badge-success")
              }
            >
              {this.state.changePc}%
            </span>
          </div>
        </div>
      </div>
    );
  }
}
