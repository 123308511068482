import * as React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export interface INewTileProps {
}

export interface INewTileState {
}

export default class NewTile extends React.Component<INewTileProps, INewTileState> {
  constructor(props: INewTileProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <Form>
            <div className="row">
                <div className="col-lg-12">
                <FormGroup>
                <Label for="tileName">Tile Name</Label>
                <Input type="text" name="tileName" id="tileNameId" placeholder="Tile Name" />
            </FormGroup>
            <FormGroup>
                <Label for="tileDesc">Tile Description</Label>
                <Input type="textarea" name="text" id="tileDesc" />
            </FormGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <FormGroup>
                        <Label for="tileUrl">Tile URL</Label>
                        <Input type="text" name="tileUrl" id="tileUrlId" placeholder="Tile URL" />
                    </FormGroup>
                </div>
            </div>
            <div className="row">
            
                <div className="col-lg-12">
                <FormGroup>
                <Label for="exampleFile">... or upload!</Label>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                        Upload your zipped UI code here and we'll host it for you!
                    </FormText>
            </FormGroup>
                </div>
            </div>
        
        </Form>
    );
  }
}
