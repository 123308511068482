import * as React from "react";
import FinanceApi from "../../api/FinanceApi";
import { SectorPerformanceModel } from "../../api/models/SectorPerformanceModel";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";

export interface IHeatMapTileProps {}

export interface IHeatMapTileState {
  performanceData: Array<SectorPerformanceModel>;
  options?: any;
}

export default class HeatMapTile extends React.Component<
  IHeatMapTileProps,
  IHeatMapTileState
> {
  api: FinanceApi = new FinanceApi();
  constructor(props: IHeatMapTileProps) {
    super(props);

    this.state = {
      performanceData: [],
    };
  }

  componentDidMount() {
    this.api.getPerformance(null).then((result: any) => {
      this.setState({
        performanceData: result,
      });

      this.prepareData(result);
    });
  }

  prepareData = (data: Array<SectorPerformanceModel>) => {
    const y = new Array<string>();

    const map = new Map();

    let min = 999999999;
    let max = -999999999;
    map.set("1 Day", new Map());
    map.set("5 Day", new Map());
    map.set("1 Mo", new Map());
    map.set("3 Mo", new Map());
    map.set("YTD", new Map());
    map.set("1 Yr", new Map());
    map.set("3 Yr", new Map());
    map.set("5 Yr", new Map());
    map.set("10 Yr", new Map());

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (item.oneDayPc < min) {
        min = item.oneDayPc;
      }
      if (item.oneDayPc > max) {
        max = item.oneDayPc;
      }
      if (item.fiveDayPc < min) {
        min = item.fiveDayPc;
      }
      if (item.fiveDayPc > max) {
        max = item.fiveDayPc;
      }
      if (item.oneMonthPc < min) {
        min = item.oneMonthPc;
      }
      if (item.oneMonthPc > max) {
        max = item.oneMonthPc;
      }

      if (item.threeMonthPc < min) {
        min = item.threeMonthPc;
      }
      if (item.threeMonthPc > max) {
        max = item.threeMonthPc;
      }
      if (item.ytdPc < min) {
        min = item.ytdPc;
      }
      if (item.ytdPc > max) {
        max = item.ytdPc;
      }

      if (item.ytdPc < min) {
        min = item.ytdPc;
      }
      if (item.ytdPc > max) {
        max = item.ytdPc;
      }

      if (item.ytdPc < min) {
        min = item.ytdPc;
      }
      if (item.ytdPc > max) {
        max = item.ytdPc;
      }

      if (item.oneYrPc < min) {
        min = item.oneYrPc;
      }
      if (item.oneYrPc > max) {
        max = item.oneYrPc;
      }

      if (item.ytdPc < min) {
        min = item.ytdPc;
      }
      if (item.ytdPc > max) {
        max = item.ytdPc;
      }

      if (item.threeYrPc < min) {
        min = item.threeYrPc;
      }
      if (item.threeYrPc > max) {
        max = item.threeYrPc;
      }

      if (item.fiveYrPc < min) {
        min = item.fiveYrPc;
      }
      if (item.fiveYrPc > max) {
        max = item.fiveYrPc;
      }

      if (item.tenYrPc < min) {
        min = item.tenYrPc;
      }
      if (item.tenYrPc > max) {
        max = item.tenYrPc;
      }

      map.get("1 Day").set(item.sectorNm, item.oneDayPc);
      map.get("5 Day").set(item.sectorNm, item.fiveDayPc);
      map.get("1 Mo").set(item.sectorNm, item.oneMonthPc);
      map.get("3 Mo").set(item.sectorNm, item.threeMonthPc);
      map.get("YTD").set(item.sectorNm, item.ytdPc);
      map.get("1 Yr").set(item.sectorNm, item.oneYrPc);
      map.get("3 Yr").set(item.sectorNm, item.threeYrPc);
      map.get("5 Yr").set(item.sectorNm, item.fiveYrPc);
      map.get("10 Yr").set(item.sectorNm, item.tenYrPc);

      y.push(item.sectorNm);
    }

    const x = new Array();
    const finalData = new Array<any>();
    let i = 0;
    let j = 0;

    for (const key of map.keys()) {
      x.push(key);
      y.forEach((sector) => {
        finalData.push([i, j++, map.get(key).get(sector)]);
      });
      j = 0;
      i++;
    }

    console.log(finalData);
    console.log(x);
    console.log(y);
    console.log(min + ", " + max);
    this.setupChart(x, y, finalData, min, max);
  };

  setupChart = (
    x: Array<any>,
    y: Array<any>,
    data: Array<any>,
    min: number,
    max: number
  ) => {
    const options = {
      chart: {
        backgroundColor: "#1c3144",
        type: "heatmap",
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },

      title: {
        text: "Sector Performance",
        style: {
          color: "#fff",
        },
      },

      xAxis: {
        categories: x,
        labels: {
          style: {
            color: "#fff",
          },
        },
      },

      yAxis: {
        categories: y,
        title: null,
        reversed: true,
        labels: {
          style: {
            color: "#fff",
          },
        },
      },

      colorAxis: {
        stops: [
          [0, "#dc3545"],
          [0.5, "#ffffff"],
          [1, "#28a745"],
        ],
        min: 0,
        max: 1,
      },

      legend: {
        align: "right",
        layout: "vertical",
        margin: 0,
        verticalAlign: "top",
        y: 25,
        symbolHeight: 280,
      },
      tooltip: {
        formatter: function () {
          var self: any = this;

          return (
            "<b>" +
            getPointCategoryName(self.point, "x") +
            "</b>:" +
            self.point.value +
            " <br><b>" +
            getPointCategoryName(self.point, "y") +
            "</b>"
          );
        },
      },
      series: [
        {
          name: "Sector Performance",
          borderWidth: 1,
          data: data,
          dataLabels: {
            enabled: true,
            color: "#000000",
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter: function (): string {
                    var self: any = this;
                    const str = self.value;
                    let minLabel = "";

                    for (let token of str.split(" ")) {
                      minLabel +=
                        token.charAt(0) + token.charAt(1) + token.charAt(2);
                    }

                    return minLabel;
                  },
                },
              },
            },
          },
        ],
      },
    };

    this.setState({
      options: options,
    });
  };

  public render() {
    if (this.state.options && this.state.performanceData) {
      return (
        <HighchartsReact highcharts={Highcharts} options={this.state.options} />
      );
    } else {
      return <p>waiting</p>;
    }
  }
}
function getPointCategoryName(point: any, dimension: any) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}
