import * as React from 'react';
import TileTable from './TileTable';
import NewTile from './NewTile';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export interface IAdminComponentProps {
}

export interface IAdminComponentState {
  showModal: boolean,
  rowData: Array<any>
}

export default class AdminComponent extends React.Component<IAdminComponentProps, IAdminComponentState> {

  constructor(props: IAdminComponentProps) {
    super(props);

    this.state = {
      showModal: false,
      rowData: [{
        name: "Stock List", desc: "Show a listing of my stocks", category: "Product"
      }, {
          name: "Stock Chart", desc: "Chart a stocks price and events", category: "Product"
      }, {
          name: "Cash Position", desc: "Show an accounts cash position", category: "Account"
      }, {
          name: "Trade Alerts", desc: "Show alerts for suspicious trades", category: "Operations"
      }, {
          name: "My Tasks", desc: "Show workflow items a user has to action", category: "Workflow"
      }, {
          name: "Fact Sheet", desc: "Quick access to fact sheet documents", category: "Materials"
      }, {
          name: "Prospectus", desc: "Quick access to prospectus documents", category: "Materials"
      }, {
          name: "Portfolio Exposure", desc: "Show a clients portfolio exposure", category: "Client"
      }, {name: "Sector Performance", desc: "Show a heatmap of sector performance", category: "Product"}]
    }
  }
  toggle = () => {
    const updatedRowData = this.state.rowData;
    updatedRowData.push({name: "Sector Performance", desc: "Show a heatmap of sector performance", category: "Product"});
    this.setState({
      showModal: !this.state.showModal,
      rowData: updatedRowData
    });
  }

  public render() {
    return (
      <div className="container mt-3">
        <div className="d-flex flex-row">
            <div>
                <h4 className="text-light">Admin</h4>
            </div>
            <div className="ml-auto mt-3">
                <button className="btn btn-primary" onClick={this.toggle}>
                    Add Tile
                </button>
            </div>
        </div>
        <div className="d-flex flex-row mt-2">
            <TileTable rowData={this.state.rowData}/>
        </div>
       
        <Modal isOpen={this.state.showModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add Tile</ModalHeader>
          <ModalBody>
            <NewTile/>          
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Save</Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
