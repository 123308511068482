import { SectorPerformanceModel } from "./models/SectorPerformanceModel";

export default class FinanceApi {
  public base =
    window.location.href.indexOf("localhost") > -1
      ? "http://terminal-services-dev.eba-mnkpwq7e.us-east-1.elasticbeanstalk.com"
      : "http://terminal-services-dev.eba-mnkpwq7e.us-east-1.elasticbeanstalk.com";

  public alphaVantageBase = "https://www.alphavantage.co/query";
  public apiKey = "U693SYXFQ5ER6TXT";
  public searchTickers = (query: string) => {
    const url = this.base + query;

    fetch(url).then((response) => {
      return response.json();
    });
  };

  getPerformance<T>(date: any): Promise<T> {
    const url = this.base + "/api/finance/performance";

    return this.api(url);
  }

  getPrice<T>(ticker: string, start?: any, end?: any): Promise<T> {
    let url = new URL(this.base + "/api/finance/price");
    url.searchParams.append("ticker", ticker);
    if (start) {
      url.searchParams.append("start", start.toString());
    }

    if (end) {
      url.searchParams.append("end", end.toString());
    }

    url.searchParams.append("dayEndOnly", "Y");
    return this.api(url.toString());
  }

  api<T>(url: string): Promise<T> {
    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  }

  public getQuote = (ticker: string) => {
    const url =
      this.base +
      "?function=GLOBAL_QUOTE&symbol=" +
      ticker +
      "&apikey=" +
      this.apiKey;

    fetch(url).then((response) => {
      return response.json();
    });
  };

  public getTimeSeries = (ticker: string) => {
    const url =
      this.base +
      "?function=TIME_SERIES_DAILY_ADJUSTED&symbol=" +
      ticker +
      "&apikey=" +
      this.apiKey;

    fetch(url).then((response) => {
      return response.json();
    });
  };
}
